import React, { createContext, useContext, useReducer } from 'react';
import { levelUsers } from 'src/content/users';
import { LevelUsers } from 'src/interfaces';
import { text } from 'src/content/de';

export enum ActionType {
  SET_CELL_WIDTH = 'SET_CELL_WIDTH',
  SET_GRID_GAP = 'SET_GRID_GAP',
  SET_GRID_DISTRIBUTION = 'SET_GRID_DISTRIBUTION',
  SET_LEVEL = 'SET_LEVEL',
  SET_SCORE = 'SET_SCORE',
  INCREMENT_SCORE = 'INCREMENT_SCORE',
  SET_OVERLAY = 'SET_OVERLAY',
  SET_GAME_OVER = 'SET_GAME_OVER',
  RESET_LEVEL = 'RESET_LEVEL',
  SET_NAME = 'SET_NAME',
  SET_LEVEL_USERS = 'SET_LEVEL_USERS',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_TEXT = 'SET_TEXT',
}

const arrayTemplateAreas = [
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
];

const getArrayTemplateAreas = () => {
  return arrayTemplateAreas.map((row) => [...row]);
};

type Actions =
  | {
      type: ActionType.SET_CELL_WIDTH;
      value: number;
    }
  | {
      type: ActionType.SET_GRID_GAP;
      value: number;
    }
  | {
      type: ActionType.SET_GRID_DISTRIBUTION;
      value: number[][];
    }
  | {
      type: ActionType.SET_LEVEL;
      value: number;
    }
  | {
      type: ActionType.SET_SCORE;
      value: number;
    }
  | {
      type: ActionType.INCREMENT_SCORE;
    }
  | {
      type: ActionType.SET_OVERLAY;
      value: boolean;
    }
  | { type: ActionType.RESET_LEVEL; value: boolean }
  | { type: ActionType.SET_NAME; value: string }
  | { type: ActionType.SET_LEVEL_USERS; value: LevelUsers[] }
  | {
      type: ActionType.SET_LANGUAGE;
      value: string;
    }
  | {
      type: ActionType.SET_TEXT;
      value: any;
    };

type Reducer = (state: State, action: Actions) => State;
interface State {
  cellWidth: number;
  setCellWidth: (value: number) => void;
  gridGap: number;
  setGridGap: (value: number) => void;
  gridDistribution: number[][];
  setGridDistribution: (value: number[][]) => void;
  level: number;
  setLevel: (value: number) => void;
  score: number;
  setScore: (value: number) => void;
  dispatch: React.Dispatch<Actions>;
  overlayState: boolean;
  setOverlayState: (value: boolean) => void;
  reset: boolean;
  resetLevel: (value: boolean) => void;
  name: string;
  setName: (value: string) => void;
  levelUsers: LevelUsers[];
  setLevelUsers: (value: LevelUsers[]) => void;
  language: string;
  setLanguage: (value: string) => void;
  text: any;
  setText: (value: string) => void;
}
const initialState: State = {
  cellWidth: 0,
  setCellWidth: () => {},
  gridGap: 0,
  setGridGap: () => {},
  gridDistribution: getArrayTemplateAreas(),
  setGridDistribution: () => {},
  level: 1,
  setLevel: () => {},
  score: 0,
  setScore: () => {},
  overlayState: false,
  dispatch: () => {},
  setOverlayState: () => {},
  reset: false,
  resetLevel: () => {},
  name: '',
  setName: () => {},
  levelUsers: levelUsers,
  setLevelUsers: () => {},
  language: 'de',
  setLanguage: () => {},
  text: text,
  setText: () => {},
};

const stateReducer: Reducer = (state, action) => {
  switch (action.type) {
    case ActionType.SET_CELL_WIDTH:
      return { ...state, cellWidth: action.value };
    case ActionType.SET_GRID_GAP:
      return { ...state, gridGap: action.value };
    case ActionType.SET_GRID_DISTRIBUTION:
      return { ...state, array: action.value };
    case ActionType.SET_LEVEL:
      return {
        ...state,
        level: action.value < 5 ? action.value : 1,
        gridDistribution: getArrayTemplateAreas(),
        score: action.value < 5 ? 0 : state.score,
      };
    case ActionType.SET_SCORE:
      return { ...state, score: action.value };
    case ActionType.INCREMENT_SCORE:
      return { ...state, score: state.score + 1 };
    case ActionType.SET_OVERLAY:
      return { ...state, overlayState: action.value };
    case ActionType.RESET_LEVEL:
      return {
        ...state,
        gridDistribution: action.value ? getArrayTemplateAreas() : state.gridDistribution,
        reset: action.value,
        score: 0,
      };
    case ActionType.SET_NAME:
      return { ...state, name: action.value };
    case ActionType.SET_LEVEL_USERS:
      return { ...state, levelUsers: action.value };
    case ActionType.SET_LANGUAGE:
      return { ...state, language: action.value };
    case ActionType.SET_TEXT:
      return { ...state, text: action.value };
    default:
      return state;
  }
};

export const Context = createContext<State>(initialState);

export const ContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer>(stateReducer, initialState);

  const ContextStore: State = {
    cellWidth: state.cellWidth,
    setCellWidth: (value: number) => dispatch({ type: ActionType.SET_CELL_WIDTH, value }),
    gridGap: state.gridGap,
    setGridGap: (value: number) => dispatch({ type: ActionType.SET_GRID_GAP, value }),
    gridDistribution: state.gridDistribution,
    setGridDistribution: (value: number[][]) => dispatch({ type: ActionType.SET_GRID_DISTRIBUTION, value }),
    level: state.level,
    setLevel: (value: number) => dispatch({ type: ActionType.SET_LEVEL, value }),
    score: state.score,
    setScore: (value: number) => dispatch({ type: ActionType.SET_SCORE, value }),
    overlayState: state.overlayState,
    setOverlayState: (value: boolean) => dispatch({ type: ActionType.SET_OVERLAY, value }),
    reset: state.reset,
    resetLevel: (value: boolean) => dispatch({ type: ActionType.RESET_LEVEL, value }),
    name: state.name,
    setName: (value: string) => dispatch({ type: ActionType.SET_NAME, value }),
    levelUsers: state.levelUsers,
    setLevelUsers: (value: LevelUsers[]) => dispatch({ type: ActionType.SET_LEVEL_USERS, value }),
    language: state.language,
    setLanguage: (value: string) => dispatch({ type: ActionType.SET_LANGUAGE, value }),
    text: state.text,
    setText: (value: string) => dispatch({ type: ActionType.SET_TEXT, value }),
    dispatch,
  };

  return <Context.Provider value={ContextStore}>{children}</Context.Provider>;
};

export const useGlobalContext = () => useContext(Context);

export const text = {
  instruction: `<h3><b>SO WIRD GESPIELT</b></h3>
        <p>
          <b>Das Ziel: </b> Schiebe das rote Auto zum Ausgang, um dem Verkehrsstau zu entkommen.
        </p>
        <p>
          <b>Der Aufbau:</b> Wähle eine Aufgabenkarte und platziere die Autos auf dem Spielfeld, wie auf der Aufgabenkarte vorgegeben.
        </p>
        <p>
          <b>Das Spiel:</b> Schiebe die blockierenden Autos und LKWs auf ihren Fahrbohnen vorwärts und rückwarts bis der Weg für das rote Auto frei ist!
        </p>
        <p>
          <b>Eine Regel: </b> Du darfst die Autos und LWKs nicht anheben, sie müssen auf ihren Fahrbahnen bleiben.
        </p>
        <p>
          <b>Wenn du feststeckst:</b> Nimm die Fahrzeuge von der Spielfläche und beginne erneut.
        </p>
        <p>
          <b>Wenn du dein Gehirn abwürgst:</b> Den kompletten Lösungsweg findest du auf der Rückseite jeder Aufgabenkarte.
        </p>
      </div>`,
  instructionDescription: `Beginner = Anfänger • Intermediate = Fortgeschrittener Advanced = Profi • Expert = Experte`,
  highScoreButton: `Highscore ansehen`,
  resetButton: `Level neustarten`,
  solutionButton: `Lösung anzeigen`,
  rulesButton: `Spielregeln`,
  nextButton: `Weiter`,
  nextLevel: `Nächstes Level`,
  startOver: `Neu anfangen`,
  storeText: `Erhältlich im Spielzeughandel`,
  storeLink: 'https://www.ravensburger.de/produkte/thinkfun/rush-hour/rush-hour-das-geniale-stauspiel-76441/index.html',
  levelFinished: {
    headline: `Herzlichen
        <br /> Glückwunsch`,
    p1: 'Du hast die Stufe',
    p2: 'gemeistert',
    p3: `Gib deine Initialien ein, um auf der Highscore-<br> Liste zu erscheinen:`,
  },
  placeText: 'Platz',
  moves: 'Züge',
};
